const feature1Container = document.getElementById("feature-animation-1");
const feature1Data = require("./../lottie/feature-01.json"); // Replace with the path to your animation JSON file
feature1Container.load(feature1Data);

const feature2Container = document.getElementById("feature-animation-2");
const feature2Data = require("./../lottie/feature-02.json"); // Replace with the path to your animation JSON file
feature2Container.load(feature2Data);

const feature3Container = document.getElementById("feature-animation-3");
const feature3Data = require("./../lottie/feature-03.json"); // Replace with the path to your animation JSON file
feature3Container.load(feature3Data);

const feature4Container = document.getElementById("feature-animation-4");
const feature4Data = require("./../lottie/feature-04.json"); // Replace with the path to your animation JSON file
feature4Container.load(feature4Data);
